<template>
    <div class="mbn-notify">
        <div class="headerBar">
            <b>Thông báo</b>

            <!-- <button title="Đánh dấu đã xem tất cả" class="markViewedAll" @click="markViewedAll()"><i class="fa fa-check"></i></button> -->

            <div class="tabType">
                <a href="javascript:;" @click="filter_type='all'" :class="filter_type=='all' ? 'active': ''">
                    Tất cả
                </a>
                <a href="javascript:;" @click="filter_type='article'" :class="filter_type=='article' ? 'active': ''">
                    Tin đăng
                </a>

                <a href="javascript:;" @click="filter_type='order'" :class="filter_type=='order' ? 'active': ''">
                    Đơn hàng
                </a>

                <a href="javascript:;" @click="filter_type='system'" :class="filter_type=='system' ? 'active': ''">
                    Tài khoản
                </a>
            </div>
        </div>

        <div class="bodyNotify">
            <ul class="listNotify">
                <li v-for="item in records" :key="item._id">
                    <a @click="read(item)" :class="generalClass(item)"  v-if="filter_type == item.category || filter_type == 'all'">
                        <span class="beforeTitle">{{ item.content.content.before_title }}</span>
                        <span class="content">{{ item.content.content.title }}</span>
                        <span class="afterTitle">{{ item.content.content.after_title }}</span>
                        <div class="date">
                            <i class="fa fa-calendar"></i>
                            {{ item.date_create|formatTime }}</div>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    data: function(){
        return {
            records: [],
            filter_type: "all"
        }
    },

    filters: {
        formatTime(time){
            var d = new Date(time);
            return [
                d.getHours(),
                d.getMinutes(),
            ].join(":") + " ngày " + [
                d.getDate(),
                d.getMonth() + 1,
                d.getFullYear()
            ].join("-");
        }
    },
    methods: {
        markViewedAll() {
            var that = this;
            var url = url_notification + "notification/mark-read/" + user_logged.id;

            let req = {
                url,
                method: 'PUT'
            }
            axios(req).then(response => {
                that.records.map(function(item) {
                    that.$set(item, 'status', 'readed');
                });
            }, response => {
                
            });
        },
        read(item) {
            var that = this;
            var url = url_notification + "change-status-notify/" + item._id
            axios.post(url).then(function(res){
                that.$set(item, 'status', 'readed');
                if (item.url != '') {
                    location.href = item.url
                }
            });
        },
        generalClass(item){
            var output = ["itemNotify"];

            if (item.status == 'unread') {
                output.push("unread")
            }
            output.push(item.category);
            output.push(item.content.type);

            return output.join(" ");
        }
    },
    mounted: function(){
        var that = this;
        var url = url_notification + "notification/" + user_logged.id
        axios.get(url).then(function(res){
            that.$set(that, 'records', res.data.result);
        });
    }
}
</script>

<style lang="less">
    
    .mbn-notify {
        max-width: 360px;
        margin: auto;
        box-shadow: 0 1px 6px rgba(0,0,0,0.12);
        font-size: 14px;
        background-color: #fff;
        position: relative;

        

        .markViewedAll {
            position: absolute;
            right: 10px;
            top: 5px;
        }

        .headerBar {
            padding: 5px 10px;
            background-color: #e6e6e6;
            border-bottom:  1px solid #dedede
        }
        a {
            &:focus,
            &:active,
            &:hover {
                text-decoration: none;
            }
        }
        ul {
            padding: 0;
            li {
                list-style: none;
            }
            a {
                display: block;
                padding: 5px 10px;
                border-bottom: 1px solid #d6d6d6;
                &:focus,
                &:active,
                &:hover {
                    text-decoration: none;
                }
            }
        }
        .tabType {
            margin-top: 5px;
            a {
                display: inline-block;
                padding: 1px 10px;
                border: 1px solid #cacaca;
                margin-right: 10px;
                border-radius: 4px;
                font-size: 11px;
                color: black;
                background-color: #fff;
                &.active {
                    background-color: #298ad8;
                    color: #fff;
                    border: 1px solid #0e6db7;
                }
            }
        }
        .bodyNotify {
            max-height: 300px;
            overflow: auto;;
        }
        .itemNotify {
            color: #444;
            background-color: #efefef;
            .content {
                display: inline-block;
                margin-left: 5px;
                margin-right: 5px;
            }
            &.unread {
                background-color: #fff;
                border-left: 3px solid #87caff;
            }

            .date {
                color: #777;
                padding-left: 0px;
                .fa {
                    margin-right: 3px;
                }
            }
        }
    }
</style>